import React from 'react';
import { toast } from 'react-toastify';
import noToggle from '../../../assets/images/noToggle.png';
import toggleOff from '../../../assets/images/toggleOff.png';
import toggleOn from '../../../assets/images/toggleOn.png';
import DataTable from '../../../common/component/table/DataTable';
import { isSuperAdminWithPermission } from '../../../utility/utility';

export function Toggle(props) {
  let buttonState = props.value ? toggleOn : toggleOff;
  const onClick = () => {
    if (props.disabled) return;
    props.onChange && props.onChange(buttonState === toggleOff);
  };
  return (
    <div className="EnDisToggleHolder">
      {/* <p>{props.value ? 'Allowed' : 'Disabled'}</p> */}
      <img
        src={buttonState}
        onClick={onClick}
        style={{ width: '32px', paddingTop: 5 }}
      />
    </div>
  );
}

export function ClearBalanceToggle(props) {
  let buttonState = props.value ? toggleOn : toggleOff;
  const onClick = () =>
    props.onChange && props.onChange(buttonState === toggleOff);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        backgroundColor: '#e1e1e1',
        padding: '8px',
        width: '193px',
      }}
      className="EnDisToggleHolder ClearCallBalance"
    >
      <p style={{ margin: 0 }}>Clear call balance</p>
      <img src={buttonState} onClick={onClick} />
    </div>
  );
}

export function CallerTuneToggle(props) {
  let buttonState = props.value ? toggleOn : toggleOff;
  //globalCallerTuneUrl callerTuneUrl
  if (!isSuperAdminWithPermission('EDIT')) {
    return (
      <div className="EnDisToggleHolder">
        <p>{'Disabled'}</p>
        <img src={noToggle} />
      </div>
    );
  }
  if (!props.globalCallerTuneUrl && !props.callerTuneUrl) {
    return (
      <div className="EnDisToggleHolder">
        <p>{'Disabled'}</p>
        <img src={noToggle} />
      </div>
    );
  }

  const onClick = () =>
    props.onChange && props.onChange(buttonState === toggleOff);
  return (
    <div className="EnDisToggleHolder">
      <p>{props.value ? 'Enable' : 'Disabled'}</p>
      <img src={buttonState} onClick={onClick} />
    </div>
  );
}

function CallForwardToggles(props) {
  let buttonState;
  let secondaryBtnState;
  let incomingPreferenceButton = props.incomingPreference === "VOIP" ? toggleOn : toggleOff;
  if (props.forwardingStatus) {
    buttonState = toggleOn;
  }
  if (!props.forwardingStatus) {
    buttonState = toggleOff;
  }
  // if (!props?.userData?.v_mobile_no || props.userData.v_mobile_no === 'NA') {
  //   buttonState = noToggle;
  // }
  if (props.forwardingState) {
    secondaryBtnState = toggleOn;
  }
  if (!props.forwardingState) {
    secondaryBtnState = toggleOff;
  }
  // if (
  //   !props?.userData?.v_mobile_no ||
  //   props.userData.v_mobile_no === 'NA' ||
  //   !props.forwardingStatus
  // ) {
  //   secondaryBtnState = noToggle;
  // }

  const forwardStatusHandler = () => {
    // if (buttonState === noToggle)
    //   return toast.error(`Virtual number not mapped`);
    if (buttonState === toggleOn) {
      props.callForwardingStatusHandler({
        user_id: props.userData.user_id,
        is_call_forwarding_allowed: false,
      });
    }
    if (buttonState === toggleOff) {
      props.callForwardingStatusHandler({
        user_id: props.userData.user_id,
        is_call_forwarding_allowed: true,
      });
    }
  };

  const forwardStateHandler = () => {
    // if (secondaryBtnState === noToggle) {
    //   if (
    //     !props?.userData?.v_mobile_no ||
    //     props.userData.v_mobile_no === 'NA'
    //   ) {
    //     return toast.error(`Virtual number not mapped`);
    //   } else {
    //     return toast.error('Forwarding disabled');
    //   }
    // }
    if (secondaryBtnState === toggleOn) {
      props.callForwardingStateHandler({
        user_id: props.userData.user_id,
        is_call_forwarding: false,
      });
    }
    if (secondaryBtnState === toggleOff) {
      props.callForwardingStateHandler({
        user_id: props.userData.user_id,
        is_call_forwarding: true,
      });
    }
  };

  const changeOutboundCallStatus = () => {
    if (secondaryBtnState === noToggle) {
      if (
        !props?.userData?.v_mobile_no ||
        props.userData.v_mobile_no === 'NA'
      ) {
        return toast.error(`Virtual number not mapped`);
      } else {
        return toast.error('Forwarding disabled');
      }
    }
    if (secondaryBtnState === toggleOn) {
      props.callForwardingStateHandler({
        user_id: props.userData.user_id,
        is_call_forwarding: false,
      });
    }
    if (secondaryBtnState === toggleOff) {
      props.callForwardingStateHandler({
        user_id: props.userData.user_id,
        is_call_forwarding: true,
      });
    }
  };
  if (props.masterCallForwarding) {
    return (
      <React.Fragment>
        <DataTable.Row style={{ borderBottom: '2px solid transparent' }}>
          <DataTable.CellHead
            style={{ width: '75%', paddingLeft: '5%', fontSize: '100%' }}
          >
            {' '}
            Incoming Calls
          </DataTable.CellHead>
          <DataTable.Cell>
            <div className="EnDisToggleHolder">
              {/* <p>{props.forwardingStatus ? 'Allowed' : 'Disabled'}</p> */}
              <img
                src={buttonState}
                onClick={() => {
                  if (!isSuperAdminWithPermission('EDIT')) return;
                  forwardStatusHandler();
                }}
                style={{ width: '32px', paddingTop: 5 }}
              />
            </div>
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row style={{ borderBottom: '2px solid transparent' }}>
          <DataTable.CellHead
            style={{ width: '75%', paddingLeft: '5%', fontSize: '100%' }}
          >
            Availability
          </DataTable.CellHead>
          <DataTable.Cell>
            <div className="EnDisToggleHolder">
              {/* <p>{props.forwardingState ? 'Allowed' : 'Disabled'}</p> */}
              <img
                src={secondaryBtnState}
                onClick={() => {
                  if (!isSuperAdminWithPermission('EDIT')) return;
                  forwardStateHandler();
                }}
                style={{ width: '32px', paddingTop: 5 }}
              />
            </div>
          </DataTable.Cell>
        </DataTable.Row>
        {props.isIncomingWebrtcEnabled &&
          <DataTable.Row style={{ borderBottom: '2px solid transparent' }}>
            <DataTable.CellHead
              style={{ width: '75%', paddingLeft: '5%', fontSize: '100%' }}
            >
              Incoming Preference - Browser
            </DataTable.CellHead>
            <DataTable.Cell>
              <div className="EnDisToggleHolder">
                {/* <p>{props.forwardingState ? 'Allowed' : 'Disabled'}</p> */}
                <img
                  src={incomingPreferenceButton}
                  onClick={() => {
                    if (!isSuperAdminWithPermission('EDIT')) return;
                    props.updatePreferenceMode();
                  }}
                  style={{ width: '32px', paddingTop: 5 }}
                />
              </div>
            </DataTable.Cell>
          </DataTable.Row>
        }
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div></div>
    </React.Fragment>
  );
}

export default CallForwardToggles;
