/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import '../../cloudCallCenter/component/styles.css';
import { OUTGOING_CALL_LOGS_MAPPER } from '../../../common/constants';
import '../../../assets/css/SearchBar.css';
import { commonStyle } from '../../../assets/css/css';
import phoneWhite from '../../../assets/images/ioscall.png';
import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import AudioPlayer from '../../cloudCallCenter/component/AudioPlayer';
import DataTable from '../../../common/component/table/DataTable';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import {
  setIsOpen,
  setDestinationNumber,
  setCallId,
  setContactId,
  setCampaignId,
  setSelectedDailer
} from '../../../actions/dailPadActions';
import AddIndividualCallDispositionsPopup from '../../cloudCallCenter/component/AddIndividualCallDispositionsPopup';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import ModifyHeadersPopUp from '../../../common/component/TableHeaders/TableHeaders';
import moment from 'moment';
import { toast } from 'react-toastify';
import CallReportSearchBar from '../component/CallReportSearchBar';
import { setCallLogsTableHeaders } from '../../../actions/recoveryXActions';
import { CallIcon } from '../../../assets/Icons/Icons';
import AICallAnalysisPopup from '../../../common/component/callAiPopup';
import { DIALER_TYPES } from '../../../common/component/dailPad/DailPad';
const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
const userUrl = process.env.REACT_APP_USER_API;

export function Calls(props) {
  const statusMapper = {
    answered: 'Answered',
    not_answered: 'Not Answered',
  };
  let displayData;
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [leadListId, setLeadListId] = useState('');
  const [totalOrganisationCalls, setTotalOrganisationCalls] = useState(0);
  const [activeLoginUsersCount, setActiveLoginUsersCount] = useState(0);
  const [totalOrganisationDuration, setTotalOrganisationDuration] = useState(0);
  const [leadListContacts, setLeadListContacts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [totalCalls, setTotalCalls] = useState(0);
  const [exportFilters, setExportFilters] = useState({});
  const [users, setUsers] = useState([]);
  const [campaignsList, setCamapignsList] = useState('');
  const [filteredCampaigns, setFilteredCampaigns] = useState('');
  const [filteredHeaders, setFilteredHeaders] = useState(
    props?.callLogsTableHeaders || []
  );
  const [isHeadersPopUpOpen, setIsHeadersPopUpOpen] = useState(false);

  useEffect(() => {
    setFilteredHeaders(
      props?.callLogsTableHeaders || [
        'INITIATOR NAME',
        'INITIATOR NUMBER',
        'DESTINATION NUMBER',
        'CALL STATUS',
        'DATE',
        'DISPOSITION',
      ]
    );
  }, [props?.callLogsTableHeaders]);
  const getLeadsCount = (count) => {
    setCount(count);
  };
  const getDispositions = () => {
    setLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(cloudCallCenterUrl + 'disposition', data)
      .then((res) => {
        setDispositions(res.data.response.dispositions);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const fetchUserData = () => {
    if (userRole === 'admin' || userRole === 'ADMIN') {
      let data = {
        pageNo: 1,
        pageSize: 100,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      };
      GET(userUrl + 'v1/user', data)
        .then((res) => {
          const users = res.data.response.users.docs.map((user) => {
            return {
              user_id: user._id.toString(),
              userName: user.zvr_name,
            };
          });
          return users;
        })
        .then((res) => {
          setUsers(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getCampaigns = () => {
    const data = {
      fetchOnlyCampaignsList: true,
      nonDeleted: true,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    if (userRole === 'USER') {
      data.userId = localStorage.getItem('doosra-biz-user-id');
    }
    GET(cloudCallCenterUrl + 'campaign/get-campaigns', data)
      .then((res) => {
        setCamapignsList(res?.data?.response?.campaignData);
        setFilteredCampaigns(res?.data?.response?.campaignData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOrganisationReport();
    getCampaigns();
    getDispositions();
    fetchUserData();
  }, []);

  const updateCallDispositonTag = (data) => {
    console.log('data', data);
    const tempLeadsData = leadListContacts.map((lead) => {
      if (lead._id === data.callId) {
        lead.disposition = data.callTag;
        lead.callComment = data.callComment || '-';
      }
      return lead;
    });
    setLeadListContacts(tempLeadsData);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  function formatTime(seconds) {
    if (seconds === 0) {
      return '-';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let formattedTime;
    if (hours > 0) {
      formattedTime = `${hours.toString().padStart(2, '0')}: ${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      formattedTime = `${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return formattedTime;
  }

  const fetchOrganisationReport = (pageNo = 1, filter = {}, reset = false) => {
    setLoading(true);
    let data = { pageNo: pageNo, pageSize: '15' };
    if (filter.searchKey && filter.searchCondition) {
      data.searchKey = filter.searchKey;
      data.searchValue = filter.searchCondition;
    }
    if (filter.searchKey === 'date' && filter.from_date && filter.to_date) {
      data.searchKey = filter.searchKey;
      data.fromDate = filter.from_date;
      data.toDate = filter.to_date;
    }

    if (
      !filter.searchKey &&
      !filter.searchValue &&
      searchKey &&
      searchValue &&
      !reset
    ) {
      data.searchKey = searchKey;
      data.searchValue = searchValue;
    }

    if (
      !filter.searchKey &&
      !filter.searchValue &&
      searchKey &&
      searchKey === 'date' &&
      fromDate &&
      toDate &&
      !reset
    ) {
      data.searchKey = searchKey;
      data.searchValue = searchValue;
      data.fromDate = fromDate;
      data.toDate = toDate;
    }
    if (filter.campaignId) {
      data.campaignId = filter.campaignId;
    }
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(cloudCallCenterUrl + 'cloud-call-logs/get-calls', data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setLeadListContacts(res.callsData);
        setExportFilters(data);
        setTotalPages(res.totalPages);
        setTotalCalls(res.totalCalls || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  if (loading) displayData = <PreLoader />;
  if (leadListContacts.length > 0) {
    displayData = leadListContacts.map((row) => (
      <DataTable.Row key={row._id}>
        {filteredHeaders.map((header) => {
          switch (header) {
            case 'INITIATOR NAME':
              return (
                <DataTable.Cell>
                  {!row.user || row.user === 'NA' ? '-' : row.user.zvr_name}
                </DataTable.Cell>
              );
            case 'INITIATOR NUMBER':
              return (
                <DataTable.Cell>
                  {!row.initiatorNumber || row.initiatorNumber === 'NA'
                    ? '-'
                    : row.initiatorNumber}
                </DataTable.Cell>
              );
            case 'CAMPAIGN NAME':
              return (
                <DataTable.Cell>
                  {!row.campaignName || row.campaignName === 'NA'
                    ? '-'
                    : row.campaignName}
                </DataTable.Cell>
              );
            case 'DESTINATION NUMBER':
              return (
                <DataTable.Cell>
                  {!row.destinationNumber || row.destinationNumber === 'NA'
                    ? '-'
                    : row.destinationNumber}
                </DataTable.Cell>
              );
            case 'CALL STATUS':
              return (
                <DataTable.Cell>
                  {!row.status || row.status === 'NA'
                    ? '-'
                    : statusMapper[row.status]}
                </DataTable.Cell>
              );
            case 'DURATION':
              return (
                <DataTable.Cell>
                  {!row.duration || row.duration === 'NA'
                    ? '-'
                    : formatTime(row.duration)}
                </DataTable.Cell>
              );
            case 'PULSE COUNT':
              return (
                <DataTable.Cell>
                  {!row.pulseCount ||
                    row.pulseCount === 'NA' ||
                    row.pulseCount === 0
                    ? '-'
                    : row.pulseCount}
                </DataTable.Cell>
              );
            case 'TIME':
              return (
                <DataTable.Cell>
                  {!row.createdAt || row.createdAt === 'NA'
                    ? '-'
                    : moment(row.createdAt).format('HH:mm:ss')}
                </DataTable.Cell>
              );
            case 'DATE':
              return (
                <DataTable.Cell>
                  {!row.createdAt || row.createdAt === 'NA'
                    ? '-'
                    : moment(row.createdAt).format('DD-MM-YYYY')}
                </DataTable.Cell>
              );
            case 'DISPOSITION':
              return (
                <DataTable.Cell>
                  {!row.disposition || row.disposition === 'NA'
                    ? '-'
                    : row.disposition}
                </DataTable.Cell>
              );
            case 'COMMENT':
              return (
                <DataTable.Cell>
                  {!row.callComment || row.callComment === 'NA'
                    ? '-'
                    : row.callComment}
                </DataTable.Cell>
              );
            case 'RECORDING':
              return (
                <DataTable.Cell>
                  {!row.duration ||
                    row.duration === 'NA' ||
                    row.duration === 0 ? (
                    ''
                  ) : (
                    <AudioPlayer
                      callRecordingUrl={row.recordingUrl}
                      callId={row._id}
                    />
                  )}
                </DataTable.Cell>
              );
          }
        })}

        <DataTable.Cell>
          <div style={{ display: 'flex' }}>
            {row.status === 'answered' &&
              userRole === 'ADMIN' &&
              localStorage.getItem('is_ai_intelligence_enabled') === 'true' && (
                <AICallAnalysisPopup
                  callId={row._id?.toString()}
                  audio={row.recordingUrl}
                  agentName={row?.user?.zvr_name}
                  name={row?.contact_name}
                  number={row?.destinationNumber}
                  duration={row.duration}
                  time={row.createdAt}
                  date={row.createdAt}
                  outgoing={true}
                  disposition={row?.disposition}
                />
              )}
            {userRole === 'USER' && (
              <>
                <AddIndividualCallDispositionsPopup
                  open={isModalOpen}
                  toggle={toggleModal}
                  dispositions={dispositions}
                  callId={row._id}
                  updateCallDispositonTag={updateCallDispositonTag}
                  isDisabled={
                    !row.disposition || row.disposition === 'RNR' ? false : true
                  }
                ></AddIndividualCallDispositionsPopup>

                <div className="tooltip-container">
                  <button
                    onClick={() => {
                      if (
                        localStorage.getItem('is_dialer_widget_enabled') ===
                        'true'
                      ) {
                        if (props.agentStatus === 'Online') {
                          props.setIsOpen({ isOpen: true });
                          props.setSelectedDailer({
                            selectedDailer: DIALER_TYPES.SOLO
                          });
                          props.setDestinationNumber({
                            destinationNumber: row.destinationNumber,
                          });
                          props.setCallId({ callId: row._id });
                          console.log('call id', row._id);
                          props.setContactId({
                            contactId: '',
                          });
                          props.setCampaignId({ campaignId: row.campaignId });
                        } else {
                          if (props.agentStatus === 'Busy') {
                            toast.error('There is a active call');
                          } else {
                            toast.error('Please go to Online');
                          }
                        }
                      }
                    }}
                    className="callingButton tooltip-button"
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CallIcon
                      style={{
                        backgroundColor: '',
                        height: '16px',
                        color: 'white',
                      }}
                    />
                  </button>

                  <div className="tooltip-text left" data-tip-position="left">
                    Click to call
                    <span className="arrow-right"></span>
                  </div>
                </div>
              </>
            )}
          </div>
        </DataTable.Cell>
      </DataTable.Row>
    ));
  }

  const tabs = [];

  if (userRole === 'ADMIN') {
    tabs.push({
      title: 'Campaigns',
      onClick: () => props.history.push('/campaigns-v2'),
      active: false,
    });
  }
  tabs.push({
    title: 'Call Logs',
    subtitle: `${totalCalls}`,
    onClick: () => props.history.push('/calls-v2'),
    active: true,
  });

  if (userRole === 'USER') {
    tabs.push({
      title: 'Leads',
      onClick: () => props.history.push('/agent-leads'),
      active: false,
    });
  }

  const tempfunction = (data = {}) => {
    setSearchKey(data.searchKey);
    setSearchValue(data.searchCondition);
    if (data.searchKey === 'date') {
      setFromDate(data.from_date);
      setToDate(data.to_date);
    }
    console.log(data);
    fetchOrganisationReport(1, data);
  };
  const resetFunction = (data = {}) => {
    setSearchKey('');
    setSearchValue('');
    setFromDate('');
    setToDate('');
    setCurrentPage(1);

    fetchOrganisationReport(1, data, true);
  };

  const updateFilteredHeader = (header, isSelected) => {
    if (isSelected) {
      setFilteredHeaders((headers) => [...headers, header]);
      props.setCallLogsTableHeaders({
        callLogsTableHeaders: [...filteredHeaders, header],
      });
    } else {
      const unFilteredHeaders = filteredHeaders.filter(
        (headerType) => headerType !== header
      );
      setFilteredHeaders(unFilteredHeaders);
      props.setCallLogsTableHeaders({
        callLogsTableHeaders: unFilteredHeaders,
      });
    }
  };

  const filterCampaigns = (name) => {
    const regexp = new RegExp(name, 'i');
    const updatedCampaigns = campaignsList.filter((campaign) => {
      const shouldFilter = regexp.test(campaign.campaignName);
      return shouldFilter;
    });
    setFilteredCampaigns(updatedCampaigns);
  };

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <TabSearchBar type={'campaigns'} tabs={tabs}>
            <ModifyHeadersPopUp
              headers={Object.keys(OUTGOING_CALL_LOGS_MAPPER)}
              updateFilteredHeader={updateFilteredHeader}
              filteredHeaders={filteredHeaders}
              isPopUpOpen={isHeadersPopUpOpen}
              openHeadersPopUp={() => setIsHeadersPopUpOpen(true)}
              closeHeadersPopUp={() => setIsHeadersPopUpOpen(false)}
            />
            <SearchBar
              type="recovery-call-logs"
              optional="campaigns"
              exportFilters={exportFilters}
              disableTitle
              count={count}
              getLeadsCount={(e) => getLeadsCount(e)}
              search={(e) => tempfunction(e)}
              reset={(e) => resetFunction(e)}
            />
          </TabSearchBar>
          <CallReportSearchBar
            type="recovery-call-logs"
            disableTitle
            count={count}
            userData={users}
            dispositions={dispositions}
            campaignsList={filteredCampaigns}
            filterCampaigns={filterCampaigns}
            getLeadsCount={(e) => getLeadsCount(e)}
            search={(e) => tempfunction(e)}
            reset={(e) => resetFunction(e)}
          />
          {/* <ReportSearchBar
            totalOrganisationCalls={totalOrganisationCalls}
            totalOrganisationDuration={totalOrganisationDuration}
            activeLoginUsersCount={activeLoginUsersCount}
            search={fetchOrganisationReport}
            reset={fetchOrganisationReport}
          /> */}
          {leadListContacts.length > 0 ? (
            <DataTable.Table style={{ minHeight: '200px' }}>
              <DataTable.Head>
                {filteredHeaders.map((header) => (
                  <DataTable.CellHead>{header}</DataTable.CellHead>
                ))}
                <DataTable.CellHead>ACTION</DataTable.CellHead>
              </DataTable.Head>
              <DataTable.Body>{displayData}</DataTable.Body>
            </DataTable.Table>
          ) : (
            <div
              style={{
                height: '300px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: 500,
              }}
            >
              No Calls
            </div>
          )}
          {loading && <PreLoader />}

          <Paginator
            currentPage={currentPage}
            lastPage={totalPages}
            getInfo={(value) => {
              setCurrentPage(value);
              fetchOrganisationReport(value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callLogsTableHeaders: state.recovery.callLogsTableHeaders,
  agentStatus: state.dailpad.agentStatus,
});
export default commonStyle(
  connect(mapStateToProps, {
    setCallLogsTableHeaders,
    setIsOpen,
    setSelectedDailer,
    setDestinationNumber,
    setCallId,
    setContactId,
    setCampaignId,
  })(Calls)
);
